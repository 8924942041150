import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { dateFormatMoment } from '@core/utils/utils'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)
  const dataUsers = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'last_name', label: t('Clients.clientList.columnName'), sortable: true },
    // { key: 'email', sortable: true },
    { key: 'phone', label: t('Clients.clientList.columnPhone'), sortable: true },
    {
      key: 'planName',
      label: t('Clients.clientList.columnPlan'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'credits',
      label: t('Clients.clientList.columnCredits'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'attendance',
      label: t('Clients.clientList.columnAttendances'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'endDate',
      label: t('Clients.clientList.columnDateEnd'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'active',
      label: t('Clients.clientList.columnStatus'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: t('Clients.clientList.columnActions'),
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref()
  const filter = ref()

  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-user/fetchUsers', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          planId: planFilter.value,
          clientStatus: statusFilter.value,
        })
        .then(response => {
          const { clients, total } = response.data
          callback(clients)
          dataUsers.value = clients
          totalUsers.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error al listar los clientes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refUserListTable.value.localItems)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const titleColumn = ['Apellido y nombre', 'DNI', 'Teléfono', 'Fecha de nacimiento', 'Dirección', 'Plan', 'Créditos', 'Asistencias', 'Creada', 'Fin de la contratación', 'Estado cliente']

  const dataExport = (users, header) => {
    const data = []
    if (header && header.length > 0) {
      data.push(header)
    }
    users.forEach(user => {
      const userDataArray = []
      userDataArray.push(`${user.last_name}, ${user.first_name}`)
      userDataArray.push(user.dni ?? '')
      userDataArray.push(user.phone ?? '')
      userDataArray.push(user.birth_date ? dateFormatMoment(user.birth_date) : '')
      userDataArray.push(user.address ?? '')
      userDataArray.push(user.memberships && user.memberships.length > 0 ? user.memberships[0].plan.name : '')
      userDataArray.push(user.memberships && user.memberships.length > 0 ? user.memberships[0].credits : '')
      userDataArray.push(user.memberships && user.memberships.length > 0 ? user.memberships[0].attendance : '')
      userDataArray.push(user.memberships && user.memberships.length > 0 ? dateFormatMoment(user.memberships[0].start) : '')
      userDataArray.push(user.memberships && user.memberships.length > 0 ? dateFormatMoment(user.memberships[0].end) : '')
      userDataArray.push(user.active ? t('Clients.status.active') : t('Clients.status.inactive'))

      data.push(userDataArray)
    })
    return data
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserMembershipStatusVariant = (status, expireToDay) => {
    if (!status && expireToDay) return 'warning'
    if (status === false) return 'success'
    if (status === true) return 'danger'
    return 'warning'
  }

  const resolveUserStatusVariant = status => {
    if (status === false) return 'danger'
    if (status === true) return 'success'
    return 'warning'
  }

  const resolveUserStatus = status => {
    if (status === false) return t('Clients.status.inactive')
    if (status === true) return t('Clients.status.active')
    return t('Clients.status.pending')
  }
  const resolveDateStatus = (status, expireToDay) => {
    if (status && !expireToDay) return t('statusMemberships.inactive')
    if (!status && expireToDay) return t('statusMemberships.expireToDay')
    if (status == false) return t('statusMemberships.activeDate')
    return t('statusMemberships.noMembership')
  }

  const genderOptions = [
    { text: 'Masculino', value: 1 },
    { text: 'Femenino', value: 2 },
    { text: 'No binario', value: 3 },
  ]

  return {
    fetchUsers,
    dataUsers,
    dataExport,
    titleColumn,

    filter,

    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    genderOptions,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatus,
    resolveUserStatusVariant,
    resolveUserMembershipStatusVariant,
    refetchData,
    resolveDateStatus,

    // Extra Filters
    planFilter,
    statusFilter,
    t,
  }
}
