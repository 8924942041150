import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { dateLatin, title } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'
import router from '@/router'

export default function usePlansList() {
  const toast = useToast()
  const { t } = useI18nUtils()

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: t('Planes.planList.planName'),
      sortable: true,
    },
    {
      key: 'discount',
      label: t('Planes.planList.discount'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'discountPercentage',
      label: t('Planes.planList.percentage'),
      // formatter: title,
      sortable: false,
    },
    {
      key: 'cost',
      label: t('Planes.planList.cost'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'months',
      label: t('Planes.planList.months'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'discipline',
      label: t('Planes.planList.disciplineName'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'created',
      label: t('Planes.planList.createdDate'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'status',
      label: t('Planes.planList.status'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: t('Planes.planList.actions'),
      sortable: false,
    },
  ]

  const refPlanListTable = ref(null)
  const planOptions = ref([0])
  const planOptionsActive = ref([0])
  const paymentMethodsOptionsActive = ref([0])
  const planData = ref(null)
  const selectedPlan = ref(null)
  const listPlans = ref(null)
  const totalRootClient = ref(0)
  const perPage = ref(10)
  const totalPlans = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPlanListTable.value ? refPlanListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlans.value,
    }
  })

  const refetchDataPlans = () => {
    refPlanListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchDataPlans()
  })

  const getPlans = () => store.dispatch('app-plan/fetchPlans')
    .then(response => {
      const { plans } = response.data

      const opDefault = [
        {
          label: 'Todos',
          value: null,
          cost: 0,
          discount: 0,
          discount_percentage: 0,
          status: null,
        },
      ]

      const opActive = []
      const op = opDefault.slice()
      plans.map(plan => {
        op.push({
          label: `${plan.id} - ${plan.name}`,
          value: plan.id.toString(),
          cost: plan.cost,
          discount: plan.discount,
          discount_percentage: plan.discount_percentage,
          status: plan.status.id.toString(),
        })
        if (plan.status.id === 1) {
          opActive.push({
            label: `${plan.id} - ${plan.name}`,
            value: plan.id.toString(),
            cost: plan.cost,
            discount: plan.discount,
            discount_percentage: plan.discount_percentage,
            status: plan.status.id.toString(),
          })
        }
      })
      planOptions.value = op
      planOptionsActive.value = opActive
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al listar los planes',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  const fetchPlans = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-plan/fetchPlans', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          // clientStatus: statusFilter.value,
        })
        .then(response => {
          const { plans, total } = response.data
          callback(plans)
          planData.value = plans
          totalPlans.value = total
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error al listar los planes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refPlanListTable.value.localItems)
    }
  }
  const addPlan = (emit, plan) => {
    store.commit('enableLoader')
    store.dispatch('app-plan/addPlan', plan)
      .then(() => {
        emit('refetch-data-plans')
        emit('update:is-add-new-plan-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Plan agregado con éxito',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      }).catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: error.response.data.error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        store.commit('disableLoader')
      })
  }
  const getPlanData = () => store.dispatch('app-plan/getPlan', { id: router.currentRoute.params.id })
    .then(response => {
      planData.value = response.data
      planData.value.status_id = response.data.status.id
      planData.value.discipline_id = response.data.discipline.id
      planData.value.discount = response.data.discount ? 1 : 0
      planData.value.free_attendance = response.data.free_attendance ? 1 : 0
      planData.value.accumulative = response.data.accumulative ? 1 : 0
    })
    .catch(error => {
      if (error.response.status === 404) {
        planData.value = undefined
      }
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al traer los datos del plan',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  const editPlanData = data => {
    store.commit('enableLoader')
    store.dispatch('app-plan/editPlan', data)
      .then(response => {
        planData.value = response.data
        planData.value.status_id = response.data.status.id
        planData.value.discipline_id = response.data.discipline.id
        planData.value.discount = response.data.discount ? 1 : 0
        planData.value.free_attendance = response.data.free_attendance ? 1 : 0
        planData.value.accumulative = response.data.accumulative ? 1 : 0
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Plan editado con éxito',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      })
      .catch(error => {
        if (error.response.status === 404) {
          planData.value = undefined
        }
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error ? error.response.data.error : 'Error al editar los datos del plan',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        store.commit('disableLoader')
      })
  }
  const deletePlan = (emit, plan) => {
    store.dispatch('app-plan/deletePlan', plan)
      .then(async () => {
        emit('refetch-data-plans')
        emit('update:is-delete-plan-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Plan desactivado',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        if (error.response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const getAllPlansAndMethods = () => store.dispatch('app-plan/fetchAllPlansAndMethods')
    .then(response => {
      const { plans, paymentMethods } = response.data

      const opActive = []
      plans.map(plan => {
        opActive.push({
          label: `${plan.id} - ${plan.name}`,
          value: plan.id.toString(),
          cost: plan.cost,
          discount: plan.discount,
          discount_percentage: plan.discount_percentage,
          status: plan.status.id.toString(),
        })
      })
      planOptionsActive.value = opActive

      const opPaymentMethodsActive = []
      paymentMethods.map(paymentMethod => {
        opPaymentMethodsActive.push({
          label: paymentMethod.name,
          value: paymentMethod.id.toString()
        })
      })

      paymentMethodsOptionsActive.value = opPaymentMethodsActive
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al listar los planes',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  const resolvePlanRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolvePlanStatusVariant = status => {
    if (status === 1) return 'success'
    if (status === 2) return 'danger'
    return 'warning'
  }
  const resolvePlanStatus = status => {
    if (status === 1) return t('Planes.status.active')
    if (status === 2) return t('Planes.status.Suspended')
    if (status === 3) return t('Planes.status.delete')
    return t('Planes.status.pending')
  }

  const resolvePlanPeriod = status => {
    if (status === 1) return t('Planes.period.monthly')
    if (status === 3) return t('Planes.period.quarterly')
    if (status === 6) return t('Planes.period.biyearly')
    if (status === 12) return t('Planes.period.yearly')
    return t('Planes.period.no-period')
  }
  const planStatusOptions = [
    { label: 'Activo', value: 1 },
    { label: 'Suspendido', value: 2 },
  ]
  const monthsOptions = [
    { label: t('Planes.period.monthly'), value: 1 },
    { label: t('Planes.period.quarterly'), value: 3 },
    { label: t('Planes.period.biyearly'), value: 6 },
    { label: t('Planes.period.yearly'), value: 12 },
  ]
  const planYesNoOptions = [
    { text: 'No', value: 0 },
    { text: 'Sí', value: 1 },
  ]

  const resolveDiscount = value => {
    if (value) return t('Planes.discount.yes')
    return t('Planes.discount.no')
  }
  const getDate = () => moment().startOf('day')

  return {
    getPlans,
    getAllPlansAndMethods,
    fetchPlans,
    addPlan,
    getPlanData,
    editPlanData,
    selectedPlan,
    resolveDiscount,
    deletePlan,
    monthsOptions,
    planData,
    planOptionsActive,
    paymentMethodsOptionsActive,
    getDate,
    listPlans,
    totalRootClient,
    planOptions,
    tableColumns,
    perPage,
    currentPage,
    totalPlans,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlanListTable,
    refetchDataPlans,
    resolvePlanRoleVariant,
    resolvePlanStatusVariant,
    resolvePlanStatus,
    resolvePlanPeriod,
    planYesNoOptions,
    planStatusOptions,
    // Extra Filters
    t,
  }
}
