<template>
  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />
    <!-- Filters -->
    <users-list-filters
      v-model="statusOptions"
      :plan-options="planOptions"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Clients.clientList.showTitle') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="ml-1">{{ t('export.title') }}</label>
            <div v-if="dataUsers && dataUsers != undefined">
              <export-xls-pdf
                  title-xls="XLS"
                  title-pdf="PDF"
                :data-export-to-pdf="dataExport(dataUsers)"
                :header-data-export-to-pdf="titleColumn"
                :file-name="t('Clients.title')"
                :data-export-to-excel="dataExport(dataUsers, titleColumn)"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                :maxlength="30"
                class="d-inline-block mr-1"
                :placeholder="$t('Clients.searchPlaceholder')+' ...'"
              />

              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ t('Clients.buttons.addClient') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('emptyText')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(last_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.id.toString()"
                :text="avatarText()"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: t('routes.view'), params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: t('routes.view'), params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.last_name + ", " + data.item.first_name }}
            </b-link>
            <small class="text-muted">{{ data.item.dni }}</small>
          </b-media>
        </template>
        <!-- Column: Teléfono -->
        <template #cell(phone)="data">
          <h6 class="text-muted">
            {{ data.item.phone ? data.item.phone :'-'}}
          </h6>
        </template>
        <!-- Column: Plan -->
        <template #cell(planName)="data">
          <h6 class="text-muted">
            {{ (data.item.memberships && data.item.memberships.length > 0 ? data.item.memberships[0].plan.name : '') }}</h6>
        </template>
        <!-- Column: credits -->
        <template #cell(credits)="data">
          <h6 class="text-muted">
            {{ (data.item.memberships && data.item.memberships.length > 0 ? data.item.memberships[0].credits : '') }}</h6>
        </template>
        <!-- Column: assitences  -->
        <template #cell(attendance)="data">
          <h6 class="text-muted">
            {{ (data.item.memberships && data.item.memberships.length > 0 ? data.item.memberships[0].attendance : '') }}</h6>
        </template>
        <!-- Column: date end -->
        <template #cell(endDate)="data">
          <span v-if="data.item.memberships && data.item.memberships.length > 0">
            <h6 class="text-normal">{{ dateLatin(data.item.memberships[0].end) }} </h6>
            <b-badge
              pill
              :variant="`light-${resolveUserMembershipStatusVariant(data.item.memberships[0].isExpired, data.item.memberships[0].isExpiresToday)}`"
              class="text-capitalize"
            >
              {{ resolveDateStatus(data.item.memberships[0].isExpired, data.item.memberships[0].isExpiresToday) }}
            </b-badge>
          </span>
          <span v-else>
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(false)}`"
              class="text-capitalize"
            >
              {{ resolveDateStatus(null) }}
            </b-badge>
          </span>
        </template>
        <!-- Column: Status -->
        <template #cell(active)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.active)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatus(data.item.active) }}
          </b-badge>
        </template>
        <!-- Column: Actions-->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: t('routes.view'), params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ t('actions.viewTitle') }}</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: t('routes.edit'), params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('actions.editTitle') }}</span>
            </b-dropdown-item>
<!--            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ t('actions.deleteTitle') }}</span>
            </b-dropdown-item>-->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{ t('total') }}: {{ totalUsers }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, dateLatin } from '@core/utils/filter'
import planStoreModule from '@/views/Plans/planStoreModule'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import ExportXlsPdf from '@/layouts/components/ExportXlsPdf.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    ExportXlsPdf,
    BTooltip,
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.getPlans()
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Activo', value: true },
      { label: 'Inactivo', value: false },
    ]

    const {
      fetchUsers,
      dataExport,
      titleColumn,
      dataUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveUserMembershipStatusVariant,
      resolveDateStatus,
      resolveUserStatusVariant,

      // Extra Filters
      planFilter,
      statusFilter,
      t,
    } = useUsersList()

    const {
      getPlans,
      planOptions,
    } = usePlansList()
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      dataExport,
      titleColumn,
      dataUsers,
      getPlans,
      planOptions,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
      dateLatin,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveDateStatus,
      resolveUserStatusVariant,
      resolveUserMembershipStatusVariant,
      statusOptions,

      // Extra Filters
      planFilter,
      statusFilter,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
