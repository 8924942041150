<template>
  <div>
    <b-link
      class="ml-2"
      on
      variant=""
      @click="exportToPdf()"
    >
      <b-avatar
        id="export-pdf-clients-icon"
        variant="light-info"
        rounded
      >
        <span>{{titlePdf}}</span>
      </b-avatar>
      <b-tooltip
        triggers="hover"
        target="export-pdf-clients-icon"
        :title="t('actions.exportPdf')"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-link>
    <b-link
      class="ml-2"
      on
      variant=""
      @click="exportToXLS()"
    >
      <b-avatar
        id="export-clients-icon"
        variant="light-info"
        rounded
      >
        {{titleXls}}
      </b-avatar>
      <b-tooltip
        triggers="hover"
        target="export-clients-icon"
        :title="t('actions.exportExcel')"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-link>
  </div>
</template>

<script>
import { exportToExcel, exportToPDF } from '@core/utils/utils'
import { BAvatar, BLink, BTooltip } from 'bootstrap-vue'
import moment from 'moment'
import { t } from '@core/libs/i18n/utils'

export default {
  components: { BTooltip, BAvatar, BLink },
  props: {
    dataExportToExcel: {
      type: Array,
    },
    dataExportToPdf: {
      type: Array,
    },
    headerDataExportToPdf: {
      type: Array,
    },
    fileName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    titleXls: {
      type: String,
    },
    titlePdf: {
      type: String,
    },
  },
  computed: {
    time() {
      return moment().format('DD-MM-YYYY-HH:mm:ss').toString()
    },
  },
  methods: {
    t,
    exportToXLS() {
      const fileName = `${this.fileName}_${this.time}`
      const data = this.dataExportToExcel
      exportToExcel(data, fileName)
    },

    exportToPdf() {
      const title = `${this.title ? this.title : `${this.fileName} ${this.time}`}`
      const dataHeader = this.headerDataExportToPdf
      const data = this.dataExportToPdf
      const fileName = `${this.fileName}_${this.time}`
      const orientation = 'landscape'

      exportToPDF(title, dataHeader, data, fileName, orientation)
    },
  },
}
</script>
