<template>
  <b-modal
    id="add-new-user-sidebar"
    ref="add-new-user-sidebar"
    :title="$t('Clients.newClient')"
    :ok-title="t('Clients.buttons.add')"
    :cancel-title="t('Clients.buttons.cancel')"
    :visible="isAddNewUserSidebarActive"
    backdrop
    no-header
    hide-footer

    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <!-- BODY -->
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="formAdd"
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.first_name')"
            rules="required"
          >
            <b-form-group
              :label="$t('Clients.data.first_name')"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="userData.first_name"
                :maxlength="50"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.first_name')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.last_name')"
            rules="required"
          >
            <b-form-group
              :label="$t('Clients.data.last_name')"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                :maxlength="50"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.last_name')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- DNI -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.dni')"
            rules= "required|integer|between:999999,999999999"
          >
            <b-form-group
              :label="$t('Clients.data.dni')"
              label-for="dni"
            >
              <b-form-input
                id="dni"
                :maxlength="9"
                v-model="userData.dni"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.dni')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.email')"
            rules= "email"
          >
            <b-form-group
              :label="$t('Clients.data.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :maxlength="50"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.email')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.phone')"
            rules="integer|between:9999999,999999999999"
          >
            <b-form-group
              :label="$t('Clients.data.phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                :maxlength="12"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.phone')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Address -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.address')"
          >
            <b-form-group
              :label="$t('Clients.data.address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                :maxlength="240"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Clients.data.address')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Birth Date -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.birthday')"
          >
            <b-form-group
              :label="$t('Clients.data.birthday')"
              label-for="birth_date"
            >
              <flat-pickr
                id="birth_date"
                v-model="userData.birth_date"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y', position: 'above', static: true }"
                placeholder="DD-MM-YYYY"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
          <!-- Sex -->
          <validation-provider
            #default="validationContext"
            :name="$t('Clients.data.sex')"
            rules="required"
          >
            <b-form-group
              :label="$t('Clients.data.sex')"
              label-for="sex"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="sex"
                v-model="userData.sex"
                :options="genderOptions"
                value=""
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- submit button -->
          <div class="d-flex flex-wrap float-right mb-2 mt-1">
            <b-button
              variant="secondary"
              class="ml-5"
              data-dismiss="add-new-user-sidebar"
              :disabled="false"
              @click="hideModal(resetForm)"
            >
              {{ t('Clients.buttons.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              type="submit"
              :disabled="invalid"
            >
              {{ t('Clients.buttons.add') }}
              <feather-icon
                v-if="loader"
                icon="SettingsIcon"
                size="15"
                class="spinner"
              />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email, max } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import useUsersList from '@/views/Clients/clients-list/useUsersList'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import userStoreModule from '@/views/Clients/userStoreModule'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [loader],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    planOptions: {
      type: Array,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  updated() {
    this.resetForm()
  },
  methods: {
    hideModal(resetForm) {
      resetForm()
      this.$refs['add-new-user-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      dni: '',
      email: '',
      phone: '',
      address: '',
      birth_date: null,
      sex: '',
      plan: null,
    }

    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const toast = useToast()
    const { t } = useUsersList()

    const onSubmit = () => {
      store.commit('enableLoader')
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Aviso',
              text: 'Cliente agregado con éxito',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          store.commit('disableLoader')
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          store.commit('disableLoader')
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const genderOptions = [
      { text: 'Masculino', value: 1 },
      { text: 'Femenino', value: 2 },
      { text: 'No binario', value: 3 },
    ]

    return {
      genderOptions,
      userData,
      onSubmit,
      toast,
      refFormObserver,
      getValidationState,
      resetForm,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
