import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/client/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post(`/client/${queryParams.id}`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/client/data/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/client', { client: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .patch(`/client/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .delete(`/client/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
