import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPlans(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/plan/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllPlansAndMethods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get('/plan')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPlan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPlan(ctx, planData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/plan', planData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPlan(ctx, planData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .patch(`/plan/${planData.id}`, planData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePlan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .delete(`/plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
